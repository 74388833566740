import { styled } from "@mui/material";
import { MuiTelInput, MuiTelInputProps } from "mui-tel-input";
import { useTranslation } from "react-i18next";

export const StyledMuiTelInput = styled(MuiTelInput)({
  "& .MuiInputAdornment-root": {
    marginLeft: 8,
    marginRight: 0,
  },
  "& .MuiInputBase-root": {
    paddingLeft: 0, // 14
  },
});

export const TelInput = (props: MuiTelInputProps) => {
  const { i18n } = useTranslation();

  return i18n.dir() === "ltr" ? (
    <MuiTelInput {...(props as any)} />
  ) : (
    <StyledMuiTelInput {...(props as any)} dir="ltr" />
  );
};
