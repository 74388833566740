export const arrayChunk = <T>(inputArray: T[], perChunk: number) => {
  return inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, [] as T[][]);
};

export const isEmptyText = (text?: string | null): text is undefined | null => {
  return text == null || typeof text !== "string" || text.trim() === "";
};

export const isNullText = (text?: any | null): text is undefined | null => {
  return text == null || (
    typeof text === "string" && (
      text === "null" || text === "undefined"
    )
  );
}
