export enum QueryKeys {
  GET_COMPETITION = "get_competition",
  GET_CUSTOM_PAGE = "get_custom_page",
  GET_FIGURES = "get_figures",
  GET_FOOTER = "get_footer",
  GET_HEADER = "get_header",
  GET_HIGHLIGHT = "get_highlight",
  GET_KEY_INVESTMENT = "get_key_investment",
  GET_OPPORTUNITY = "get_opportunity",
  GET_PAGE_HEADER = "get_page_header",
  GET_PDF = "get_pdf",
  GET_SETTINGS = "get_settings",
  GET_SUCCESS_STORY = "get_success_story",
  GET_TESTIMONIAL = "get_testimonial",
  GET_TEXT = "get_text",
  GET_USER_INFO = "get_user_info",
  GET_WHY_JORDAN = "get_why_jordan",
  GET_ZONE = "get_zone",
  LOCATIONS = "locations",
}
