import {ApiChangePassword, ApiLogin, ApiRegister, ApiResetPassword, ApiUserMe,} from "../interface";
import {wpClient} from "./client";
import {v4} from "uuid";

const AUTH_CODE = "ERBA7_SUBSCRIBER";

export class AuthApi {
  static async sendOtp(params: { username: string }) {
    const data: ApiLogin = await wpClient.post("api/v1/otp", {
      ...params,
      username: params.username.replace("+", ""),
    });

    return data;
  }

  static async login(params: { username: string; password: string }) {
    // https://api.erba7.net/?rest_route=/simple-jwt-login/v1/auth&email=Email&password=Password
    // const data: ApiLogin = await wpClient.post("jwt-auth/v1/token", params);
    //
    // return data;
    const data: ApiLogin = await wpClient.post("simple-jwt-login/v1/auth", {
      ...params,
      AUTH_CODE,
      username: params.username.replace("+", ""),
    });

    return data;
  }

  static async register(params: {
    email: string;
    password: string;
    user_login: string;
    display_name: string;
  }) {
    const data: ApiRegister = await wpClient.post(
      "simple-jwt-login/v1/register",
      {
        ...params,
        AUTH_CODE,
        email: `random-email-${v4()}@erba7.net`,
        password: v4(),
      }
    );

    return data;
  }

  static async resetPassword(params: { email: string }) {
    const data: ApiResetPassword = await wpClient.post(
      "simple-jwt-login/v1/user/reset_password",
      {
        ...params,
        AUTH_CODE,
      }
    );

    return data;
  }

  static async changePassword(params: {
    code: string;
    email: string;
    new_password: string;
  }) {
    const data: ApiChangePassword = await wpClient.put(
      "simple-jwt-login/v1/user/reset_password",
      {
        ...params,
        AUTH_CODE,
      }
    );

    return data;
  }

  static async getUserInfo(token: string) {
    const data: ApiUserMe = await wpClient.get("wp/v2/users/me", {
      params: {
        JWT: token,
        acf_format: "standard",
      },
    });

    return data;
  }
}
