import {ApiContactFrom, ApiUserMe} from "../interface";
import {wpClient} from "./client";

export class ContactForm7Api {
  static async feedback(
    token: string | null,
    formId: string,
    params: Record<string, string>,
    userInfo?:  Record<string, any>,
  ) {
    if (userInfo == null) {
      const user: ApiUserMe = await wpClient.get("wp/v2/users/me", {
        params: {
          JWT: token,
          acf_format: "standard",
        },
      });

      userInfo = {
        ...user.acf,
        ...user.extra,
        id: user.id,
      };
    }

    const data: ApiContactFrom = await wpClient.post(
      `contact-form-7/v1/contact-forms/${formId}/feedback`,
      {
        ...params,
        ...userInfo,
        _wpcf7_unit_tag: formId,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return data;
  }
}
