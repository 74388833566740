import {CircularProgress, Stack} from "@mui/material";

export const LoadingPage = () => (
  <Stack
    sx={{
      alignItems: 'center',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      position: 'fixed',
      top: 0,
      width: '100vw',
    }}
  >
    <CircularProgress/>
  </Stack>
);
