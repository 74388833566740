import {
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {useMutation, useQuery} from "@tanstack/react-query";
import dayjs from "dayjs";
import {ReactNode, useCallback, useMemo} from "react";
import {useCookies} from "react-cookie";
import {Controller, FieldValues, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {ContactForm7Api} from "../utilities/api/ContactForm7";
import {useCondition, useSettings} from "../utilities/hooks";
import {CompetitionItem} from "../utilities/interface";
import {Control} from "react-hook-form/dist/types";
import {isEmptyText} from "../utilities/common";
import {Image} from "./Image";
import {LoadingButton} from "@mui/lab";
import {ControllerFieldState, ControllerRenderProps} from "react-hook-form/dist/types/controller";
import {TelInput} from "./TelInput";
import {AuthApi} from "../utilities/api/Auth";
import {QueryKeys} from "../constants/queryKeys";
import {LoadingPage} from "../pages/LoadingPage";

interface ContainerProps {
  children: ReactNode;
}

const Container = ({children}: ContainerProps) => {
  return (
    <Stack
      sx={{
        alignItems: "stretch",
        alignSelf: "center",
        backgroundColor: "#f2f2f2",
        borderRadius: 3,
        justifyContent: "center",
        maxWidth: '100%',
        px: 2,
        py: 4,
      }}
    >
      {children}
    </Stack>
  );
};

interface InputProps {
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
  item: CompetitionItem;
}

const Input = ({ field, fieldState, item }: InputProps) => {
  const { i18n } = useTranslation("contactForm");

  const items = useMemo(() => {
    if (item.value === "") {
      return item.value;
    }

    const data = item.value.split("\r\n");

    return item.shuffle
      ? data
        .map(value => ({ sort: Math.random(), value }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
      : data;
  }, [item.shuffle, item.value]);

  if (items === "") {
    switch (item.type) {
      case "phone": {
        return (
          <TelInput
            {...field}
            color="success"
            defaultCountry="JO"
            error={fieldState.invalid}
            langOfCountryName={i18n.language}
            onlyCountries={['JO']}
            variant="outlined"
          />
        );
      }

      default: {
        const type = item.type === 'select' ? 'text' : item.type;

        return (
          <TextField
            {...field}
            color="success"
            error={fieldState.invalid}
            label=""
            type={type}
            variant="outlined"
          />
        );
      }
    }
  }

  switch (item.type) {
    case "checkbox": {
      return items.map((value) => (
        <FormControlLabel {...field} control={<Checkbox />} key={value} label={value} value={value} />
      ));
    }

    case "radio": {
      return (
        <RadioGroup {...field}>
          {items.map((value) => (
            <FormControlLabel control={<Radio />} key={value} label={value} value={value} />
          ))}
        </RadioGroup>
      );
    }

    case "multiple_select":
    case "select": {
      const isMultiple = item.type === 'multiple_select';

      return (
        <Select
          {...field}
          color="success"
          error={fieldState.invalid}
          multiple={isMultiple}
          renderValue={isMultiple ? (selected) => selected.join(', ') : undefined}
        >
          {items.map((value) => (
            <MenuItem key={value} value={value}>
              {isMultiple ? <Checkbox checked={field.value.includes(value)} /> : null}
              <ListItemText primary={value} sx={{ whiteSpace: 'normal' }} />
            </MenuItem>
          ))}
        </Select>
      );
    }
  }
}

interface CompetitionInputProps {
  control: Control;
  item: CompetitionItem;
  userInfo?: Record<string, any>;
  values: Record<string, any>;
}

const CompetitionInput = ({control, item, userInfo = {}, values}: CompetitionInputProps) => {
  const showInput = useCondition(item.condition, values);
  const isMultiple = useMemo(() => {
    return item.type === 'multiple_select' || item.type === 'checkbox';
  }, [item.type]);

  if (!showInput) {
    return null;
  }

  return (
    <>
      {isEmptyText(item.name) ? (
        <Typography>{item.label}</Typography>
      ) : (
        <FormControl fullWidth>
          <Controller
            control={control}
            defaultValue={isMultiple ? [] : userInfo[item.name] ?? ''}
            disabled={userInfo[item.name] != null}
            name={item.name}
            render={({field, fieldState}) => (
              <>
                <Typography variant="h6">{item.label}</Typography>
                <Input field={field} fieldState={fieldState} item={item} />
              </>
            )}
            rules={{required: item.required}}
          />
        </FormControl>
      )}
      <Stack sx={{ alignItems: "center" }}>
        <Image src={item.image} sx={{ borderRadius: 6, maxWidth: "100%" }} />
      </Stack>
    </>
  );
};

interface CompetitionProps {
  end_date: string;
  end_message: string;
  id: string;
  items: CompetitionItem[];
  send_condition: string;
  start_date: string;
  start_message: string;
  success_message: string;
}

export const Competition = ({
  end_date,
  end_message,
  id,
  items,
  send_condition,
  start_date,
  start_message,
  success_message,
}: CompetitionProps) => {
  const {t, i18n} = useTranslation("contactForm");
  const [cookies] = useCookies(["token"]);

  const {
    data: userInfo,
    isLoading,
  } = useQuery({
    enabled: cookies.token != null,
    queryFn: async () => {
      const user = await AuthApi.getUserInfo(cookies.token);

      return {
        ...user.acf,
        ...user.extra,
        id: user.id,
      };
    },
    queryKey: [QueryKeys.GET_USER_INFO],
  });

  console.log('emad userInfo', userInfo);

  const settings = useSettings();

  const {watch, control, handleSubmit, formState} = useForm({
    mode: "onChange",
  });

  const watchAllFields = watch();

  const {mutate, isPending, isSuccess} = useMutation({
    mutationFn: (evt: FieldValues) =>
      ContactForm7Api.feedback(cookies.token, id, evt as any),
    onSuccess: (data) => {
      // setToken(data.jwt);
      // navigate(`/${settings?.languages[i18n.language].home_slug}`);
    },
  });

  const handleSend = useCallback((evt: FieldValues) => mutate(evt), [mutate]);
  const showSend = useCondition(send_condition, watchAllFields);

  if (isLoading) {
    return (
      <LoadingPage />
    );
  }

  if (isSuccess) {
    return (
      <Container>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
            lineHeight: "25px",
            textAlign: "center",
          }}
        >
          {success_message}
        </Typography>
      </Container>
    );
  }

  // Y-m-d H:i:s
  if (dayjs().isBefore(dayjs(start_date))) {
    return (
      <Container>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
            lineHeight: "25px",
            textAlign: "center",
          }}
        >
          {start_message}
        </Typography>
      </Container>
    );
  }

  if (dayjs().isAfter(dayjs(end_date))) {
    return (
      <Container>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 600,
            lineHeight: "25px",
            textAlign: "center",
          }}
        >
          {end_message}
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Stack component="form" spacing={2} onSubmit={handleSubmit(handleSend)}>
        {items.map((item, index) => (
          <CompetitionInput
            control={control}
            item={item}
            key={index}
            userInfo={userInfo}
            values={watchAllFields}
          />
        ))}

        {showSend && (
          <LoadingButton
            color="secondary"
            disabled={!formState.isDirty || !formState.isValid}
            loading={isPending}
            type="submit"
            variant="contained"
          >
            {t("SEND")}
          </LoadingButton>
        )}
      </Stack>
    </Container>
  );
};
